@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSans-Regular"),
    url("./Fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansBold";
  src: local("OpenSans-Bold"),
    url("./Fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "OpenSansRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  box-shadow: 2px 2px 4px 0px rgba(33, 35, 37, 0.15);
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  > .MuiList-root.MuiMenu-list.MuiList-padding
  > li {
  font-size: 0.75rem;
  border-bottom: 1px solid #f2f2f2;
  font-family: "OpenSansRegular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  > .MuiList-root.MuiMenu-list.MuiList-padding
  > li.Mui-selected {
  color: #205fd4;
  background-color: transparent;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  > .MuiList-root.MuiMenu-list.MuiList-padding
  > li.Mui-selected:hover {
    background-color: transparent;
  } 

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  > .MuiList-root.MuiMenu-list.MuiList-padding
  > li:hover {
  background-color: #d4eefd;
}

@media (max-width: 768px) {
  #buttonFullMobile {
    width: 100%;
    height: 40px;
  }
  #panelItemStyled {
    width: 100%;
    margin-bottom: 1rem;
  }
  #titleMobile {
    margin: 1.5rem 0 0.5rem 0rem;
  }
  #painel-small {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  #painel-small {
    padding: 28px 120px 0 8px;
  }

  #painel-medium {
    padding: 28px 8px 0 120px;
  }
  #painel-large {
    padding: 28px 120px 0 120px;
  }

  #panelItemStyled {
    padding-right: 1rem;
  }
}

@media (min-width: 1520px) {
  #painel-large {
    padding: 28px 232px 0 120px;
  }
}

@media (min-width: 1920px) {
  #header-item {
    width: 105%;
  }
}
